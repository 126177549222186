/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */

import { UserFees } from "pgecom";
import isEmpty from "lodash.isempty";
import numeral from "numeral";
import {
  IUserSetting,
  TransactionType,
  UserType,
  IGlobalSettings,
  IUser,
  IGlobalCardSetting,
  IGlobalSettingsUpdate,
  IOrderHistory,
  IPaymentMethod,
} from "@Types";
import { GiftCardDelivery, IGlobalGiftCardSettings } from "@Types/Igiftcard";
import { IGlobalWithdrawSettings } from "@Types/Iwithdraw";
import { IBankName } from "@Types/Ibank";
import { isBankHaiti } from "./formatUtils";
import { isEligibleForDiscount } from "./orderUtils";

interface ISetting extends IGlobalSettings {
  agentSetting: IUserSetting;
  transactionType: string;
  isAgent: boolean;
}

type IHandleFeesParams = {
  amount: number;
  transactionType: string;
  mainSetting: any;
  isAgent?: boolean;
  isPartner?: boolean;
  monCashFees?: number;
};

export const calculateUserFees = (setting: IHandleFeesParams) => {
  const {
    amount,
    transactionType,
    mainSetting,
    isAgent,
    isPartner,
    monCashFees,
  } = setting;
  const userFees = new UserFees(amount);

  let userType = UserType.USER;

  if (isAgent) {
    userType = UserType.AGENT;
  } else if (isPartner) {
    userType = UserType.PARTNER;
  }
  const fees = {
    totalFees: userFees.getTotalVirtualCardFee(mainSetting),
    userFees: getVirtualCardFees(userType),
    transactionType,
    agentFees: 0,
  };

  switch (transactionType) {
    case "virtualcard":
      fees.userFees = getVirtualCardFees(userType);
      break;
    case "paypal":
      fees.totalFees = 0;
      fees.userFees = getPayPalFees(amount);
      fees.agentFees = 0;
      break;

    case "transfer":
      fees.userFees = 0;
      break;

    case "topup":
      fees.userFees = 0;
      break;

    case "balance":
      fees.userFees = getBalanceFees({ amount, userType });
      break;

    case "crypto":
      fees.userFees = getCryptoFees({ amount });
      break;

    case "zelle":
      fees.userFees = getZelleFees({ amount });
      break;

    case "venmo":
      fees.userFees = getVenmoFees({ amount });
      break;

    case "cashapp":
      fees.userFees = getCashAppFees({ amount });
      break;

    case "ht":
      const usdToGourdes = convertHTtoUSD({
        gourdes: amount,
        exchangeAmount: monCashFees,
      });
      fees.userFees = usdToGourdes?.transactionFees;
      fees.totalFees = usdToGourdes?.totalAmount;
      fees.agentFees = usdToGourdes?.exchangeAmount;
      break;

    case "usd":
      const gourdesToUsd = convertUsdToGdes({
        amount,
        exchangeAmount: monCashFees,
      });
      fees.userFees = gourdesToUsd?.transactionFees;
      fees.totalFees = gourdesToUsd?.totalAmount;
      fees.agentFees = gourdesToUsd?.exchangeAmount;
      break;
    case "physicalcard":
      fees.userFees = getPhysicalCardFees(userType);
      break;
    case "payme":
      fees.userFees = getPaymeFees({ amount });
      break;

    default:
      break;
  }

  return fees;
};

type IPaymeFees = {
  percentage?: number;
  amount: number;
};

type IBalanceFees = {
  userType?: "agent" | "partner" | "user";
  amount: number;
};

type IMonCashFees = {
  exchangeAmount?: number;
  amount: number;
  feePercentage?: number;
  fixedFee?: number;
};

type IHTtoUSD = {
  exchangeAmount: number;
  gourdes: number;
  feePercentage?: number;
  fixedFee?: number;
};

export const getPaymeFees = ({ percentage, amount }: IPaymeFees) => {
  const fAmount = numeral(amount).value() || 0;
  const feePercentage = percentage || 7;
  let transactionFees = (feePercentage / 100) * fAmount;

  if (fAmount < 40) {
    transactionFees = 3;
  }

  transactionFees += 0.5;
  return transactionFees;
};

export const getPayPalFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;
  const feePercentage = 4.49;
  const transactionFees = (feePercentage / 100) * fAmount;

  return displayDecimal(transactionFees);
};

type IGiftCardFee = {
  amount?: number;
  delivery: GiftCardDelivery;
  quantity?: number;
  giftCard: IGlobalGiftCardSettings;
  latestOrders?: IOrderHistory[];
};

export const getGiftCardFees = ({
  amount,
  delivery,
  giftCard,
  latestOrders,
}: IGiftCardFee): number => {
  const standardFees = giftCard?.standardDeliveryFee || 0;
  const regularFees = giftCard?.regularDeliveryFee || 0;
  const expressFees = giftCard?.expressDeliveryFee || 0;
  const urgentFees = giftCard?.urgentDeliveryFee || 0;
  const instantDeliveryFee = giftCard?.instantDeliveryFee || 0;
  const discountedInstantDeliveryFee = giftCard?.discountedInstantDeliveryFee;

  // Default percentage fees
  let percentageFees = Number(giftCard?.feePercentage);
  const fixedDepositFees = Number(giftCard?.fixedFee || 0);

  const isDiscounted = isEligibleForDiscount(latestOrders, amount);

  // Set percentage fees based on delivery
  if (delivery === GiftCardDelivery.STANDARD) {
    percentageFees = standardFees || percentageFees;
  } else if (delivery === GiftCardDelivery.REGULAR) {
    percentageFees = regularFees || percentageFees;
  } else if (delivery === GiftCardDelivery.EXPRESS) {
    percentageFees = expressFees || percentageFees;
  } else if (delivery === GiftCardDelivery.URGENT) {
    percentageFees = urgentFees || percentageFees;
  } else if (delivery === GiftCardDelivery.INSTANT) {
    percentageFees = isDiscounted
      ? discountedInstantDeliveryFee
      : instantDeliveryFee;
  }

  const depositFees = (percentageFees / 100) * amount;

  const totalFees = depositFees + fixedDepositFees;

  return totalFees;
};

export const getShoppingFees = ({
  amount,
  paymentMethod,
  shopping,
}: {
  amount: number;
  paymentMethod: IPaymentMethod;
  shopping?: IGlobalSettingsUpdate;
}): number => {
  const feePercentage = Number(shopping?.depositPercentageFees);

  // MonCash charges 3% fees
  const monCashFees = 0.03 * amount;

  const fees = (feePercentage / 100) * amount;

  const isMoncash = paymentMethod === IPaymentMethod.MONCASH;

  const totalFees = isMoncash ? monCashFees + fees : fees;

  return totalFees;
};

export const bankWithdrawDrawFees = (amount: number, bankName: IBankName) => {
  const isHaiti = isBankHaiti(bankName);
  const isWise = bankName === IBankName.WISE;

  // Internal Banks
  const isBOA = bankName === IBankName.BANK_OF_AMERICA;
  const isMercury = bankName === IBankName.MERCURY;
  const isPayoneer = bankName === IBankName.PAYONEER;
  const isSogebank = bankName === IBankName.SOGEBANK;

  if (isSogebank) {
    const sogebankFees = 3.5 + (1.5 / 100) * amount;

    return displayDecimal(sogebankFees);
  }

  if (isHaiti || isWise) {
    const generalFees = 6.5 + (3.5 / 100) * amount;
    return displayDecimal(generalFees);
  }

  const wireFees = 30 + (3.5 / 100) * amount;

  if (isBOA || isMercury || isPayoneer) {
    return displayDecimal(wireFees);
  }

  return displayDecimal(wireFees);
};

export const paypalWithdrawFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;

  return 4.5 + (2.5 / 100) * fAmount;
};

export const cashAppWithdrawFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;
  return 4.5 + (2.5 / 100) * fAmount;
};

export const zelleWithdrawFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;
  return 4.5 + (2.5 / 100) * fAmount;
};

export const getCashAppDepositFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;
  const percentage = (2.5 / 100) * fAmount;

  return 3.5 + percentage;
};

export const getBankReservasDeposits = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;
  const percentage = (5 / 100) * fAmount;

  const totalAmount = amount + percentage;

  return totalAmount / 60;
};

export const giftCardDeliveryFees = (
  delivery: GiftCardDelivery,
  giftCard?: IGlobalGiftCardSettings
) => {
  let transactionFees = 0;

  switch (delivery) {
    case GiftCardDelivery.STANDARD:
      transactionFees = giftCard?.standardDeliveryFee || 0;
      break;

    case GiftCardDelivery.REGULAR:
      transactionFees = giftCard?.regularDeliveryFee || 0;
      break;

    case GiftCardDelivery.EXPRESS:
      transactionFees = giftCard?.expressDeliveryFee || 0;
      break;

    case GiftCardDelivery.URGENT:
      transactionFees = giftCard?.urgentDeliveryFee || 0;
      break;

    case GiftCardDelivery.INSTANT:
      transactionFees = giftCard?.regularDeliveryFee || 0;
      break;

    default:
      transactionFees = 1.5;
  }

  return displayDecimal(transactionFees);
};

export const getDeliveryFees = ({
  delivery,
  setting,
}: {
  delivery: GiftCardDelivery;
  setting?: IGlobalCardSetting;
}) => {
  let transactionFees = 0;

  switch (delivery) {
    case GiftCardDelivery.STANDARD:
      transactionFees = setting?.standardDeliveryFee || 0;
      break;

    case GiftCardDelivery.REGULAR:
      transactionFees = setting?.regularDeliveryFee || 0;
      break;

    case GiftCardDelivery.EXPRESS:
      transactionFees = setting?.expressDeliveryFee || 0;
      break;

    case GiftCardDelivery.URGENT:
      transactionFees = setting?.urgentDeliveryFee || 0;
      break;

    case GiftCardDelivery.INSTANT:
      transactionFees = setting?.instantDeliveryFee || 0;
      break;

    default:
      transactionFees = 0;
  }

  return displayDecimal(transactionFees);
};

export const getMonCashExternalIntegrationFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;
  const transactionFees = (2.9 / 100) * fAmount;

  return transactionFees;
};

export const getMasterCardReloadFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;
  const providerFees = getMasterCardProviderFees(fAmount);
  const addingFundFee = getMasterCardAddingFundsFees(fAmount);

  const transactionFees = getMasterCardRechargeProfit(fAmount);

  const totalFees = providerFees + addingFundFee + transactionFees;

  return totalFees;
};

const getMasterCardProviderFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;

  let transactionFees = (1.5 / 100) * fAmount;

  transactionFees += 0.5;

  return transactionFees;
};

export const getMasterCardRechargeProfit = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;

  const transactionFees = (5 / 100) * fAmount;

  return transactionFees;
};

const getMasterCardAddingFundsFees = (amount: number) => {
  const fAmount = numeral(amount).value() || 0;

  const transactionFees = (4 / 100) * fAmount;

  return transactionFees;
};

export const getCreditCardFees = (amount: number, isPayme?: boolean) => {
  const fAmount = numeral(amount).value() || 0;

  let percentage = 4;

  if (isPayme) {
    percentage = 6;
  }
  const transactionFees = (percentage / 100) * fAmount;

  return transactionFees;
};

export const monCashGourdesfees = (gourdes: number) => {
  const fAmount = numeral(gourdes).value() || 0;

  const percentage = 2.5;

  const transactionFees = (percentage / 100) * fAmount;

  return displayDecimal(transactionFees);
};

export const getCashAppFees = ({ percentage = 3, amount }: IPaymeFees) => {
  const fAmount = numeral(amount).value() || 0;
  let transactionFees = (percentage / 100) * fAmount;

  if (fAmount < 100) {
    transactionFees = 3;
  } else {
    transactionFees = (7 / 100) * fAmount;
  }

  transactionFees += 0.3;
  return transactionFees;
};

export const getZelleFees = ({ percentage = 3, amount }: IPaymeFees) => {
  const fAmount = numeral(amount).value() || 0;
  let transactionFees = (percentage / 100) * fAmount;

  if (fAmount < 100) {
    transactionFees = 3;
  }

  transactionFees += 0.3;
  return transactionFees;
};

export const getUnibankFees = ({ amount }: any) => {
  const fAmount = numeral(amount).value();
  let transactionFees = (5 / 100) * fAmount;

  transactionFees += 0.3;
  return transactionFees;
};

export const getPGPayFees = ({ amount }: IPaymeFees) => {
  const fAmount = numeral(amount).value() || 0;
  let transactionFees = (1.5 / 100) * fAmount;

  transactionFees += 0.6;
  return transactionFees;
};

export const getVirtualCardFees = (
  userType: "user" | "agent" | "partner" | string
) => {
  let cardFees = 8;

  if (userType === "agent") {
    cardFees = 3.5;
  } else if (userType === "partner") {
    cardFees = 1.5;
  } else {
    cardFees = 8;
  }

  return cardFees;
};

export const getVirtualMasterCardFee = (cardholderId: string) => {
  if (!isEmpty(cardholderId)) {
    return 3.5;
  }

  return 8;
};

export const getPhysicalCardFees = (
  userType: "user" | "agent" | "partner" | string
) => {
  let cardFees = 20;

  if (userType === "agent") {
    cardFees = 15;
  } else if (userType === "partner") {
    cardFees = 13;
  } else {
    cardFees = 20;
  }

  return cardFees;
};

export const getPhysicalShipingCost = (
  shippingType: "express" | "priority"
) => {
  let shippingFees = 17;

  if (shippingType === "express") {
    shippingFees = 17;
  } else if (shippingType === "priority") {
    shippingFees = 22;
  } else {
    shippingFees = 17;
  }

  return shippingFees;
};

export const getMonCashDepositForAgent = (
  totalGourdes: number,
  htExchangeRate: number = 130
) => {
  const fAmount = numeral(totalGourdes).value() || 0;
  const transactionFees = (6 / 100) * htExchangeRate;
  const exchangeRate = htExchangeRate + transactionFees;

  const totalAmount = fAmount / exchangeRate;

  return totalAmount;
};

export const getMonCashWithdrawFees = (
  delivery: GiftCardDelivery,
  totalAmount: number
) => {
  const fAmount = numeral(totalAmount).value() || 0;
  const transactionFees = (5 / 100) * fAmount;

  if (delivery === GiftCardDelivery.STANDARD) {
    return 0;
  }

  if (delivery === GiftCardDelivery.REGULAR) {
    return (3.5 / 100) * fAmount;
  }

  if (delivery === GiftCardDelivery.EXPRESS) {
    return (5 / 100) * fAmount;
  }

  return transactionFees;
};

export const getNatCashWithdrawFees = (
  delivery: GiftCardDelivery,
  totalAmount: number,
  fees?: number
) => {
  const fAmount = numeral(totalAmount).value() || 0;
  let transactionFees = (2.5 / 100) * fAmount;

  if (delivery === GiftCardDelivery.REGULAR) {
    transactionFees = (3.5 / 100) * fAmount;
  }

  if (delivery === GiftCardDelivery.EXPRESS) {
    transactionFees = (4.5 / 100) * fAmount;
  }

  transactionFees += fees;

  if (delivery === GiftCardDelivery.STANDARD) {
    return 0;
  }

  return transactionFees;
};

export const getVenmoFees = ({ percentage = 3, amount }: IPaymeFees) => {
  const fAmount = numeral(amount).value() || 0;
  let transactionFees = (percentage / 100) * fAmount;

  if (fAmount < 100) {
    transactionFees = 3;
  }

  transactionFees += 0.3;
  return transactionFees;
};

export const getTopupFees = () => 0.5;

export const getPayPalPayoutFees = ({ percentage = 4, amount }: IPaymeFees) => {
  const fAmount = numeral(amount).value() || 0;
  let transactionFees = (percentage / 100) * fAmount;

  if (fAmount < 60) {
    transactionFees = 3;
  }

  transactionFees += 0.3;
  return transactionFees;
};

export const convertUsdToGdes = ({
  amount,
  exchangeAmount = 126.87,
  feePercentage = 8,
  fixedFee = 0,
}: IMonCashFees): {
  totalAmount: number;
  transactionFees: number;
  exchangeAmount: number;
  moncashFees: number;
  bankFees: number;
  pgecomFees: number;
  originalAmount: number;
  totalFees: number;
} => {
  const transactionFees = (feePercentage / 100) * exchangeAmount;
  let fAmount = Number(amount) || 0;

  if (fixedFee > 0) {
    fAmount += fixedFee;
  }

  const conversion = displayDecimal(exchangeAmount + transactionFees);
  const originalAmount = displayDecimal(fAmount * exchangeAmount);

  // Fees
  const moncashFees = displayDecimal((2 / 100) * originalAmount);
  const bankFees = displayDecimal((4 / 100) * originalAmount);
  const pgecomFees = displayDecimal((2 / 100) * originalAmount);

  // originalAmount += transactionFees;
  const totalAmount = displayDecimal(conversion * fAmount);
  const totalFees = displayDecimal(moncashFees + bankFees + pgecomFees);

  return {
    totalAmount,
    transactionFees: transactionFees * fAmount,
    exchangeAmount,
    moncashFees,
    bankFees,
    pgecomFees,
    originalAmount,
    totalFees,
  };
};

export const convertHTtoUSD = ({
  gourdes,
  exchangeAmount = 126.87,
  feePercentage = 8,
  fixedFee = 0,
}: IHTtoUSD) => {
  const transactionFees = (feePercentage / 100) * exchangeAmount;

  const conversion = displayDecimal(exchangeAmount + transactionFees);

  let totalAmount = displayDecimal(gourdes / conversion);

  if (fixedFee > 0) {
    totalAmount += fixedFee;
  }

  return {
    totalAmount: displayDecimal(totalAmount),
    transactionFees: displayDecimal(transactionFees * exchangeAmount),
    exchangeAmount,
  };
};

export const displayDecimal = (num) => Number(Number(num || 0)?.toFixed(2));

export const getBalanceFees = ({ userType = "user", amount }: IBalanceFees) => {
  const fAmount = numeral(amount).value() || 0;
  let feePercentage = 7;

  if (userType === "user") {
    feePercentage = 7;
  } else if (userType === "agent") {
    feePercentage = 5;
  } else {
    feePercentage = 4;
  }

  let transactionFees = (feePercentage / 100) * fAmount;

  if (fAmount < 40 && userType === "user") {
    transactionFees = 3;
  } else if (fAmount < 40 && userType !== "user") {
    transactionFees = 2;
  }

  transactionFees += 0.35;
  return Math.round(transactionFees);
};

export const getCryptoFees = ({ amount }: IBalanceFees): number => {
  const fAmount = numeral(amount).value() || 0;
  const minimumAmout = 50;
  let fixFees = 2;
  let percentage = 13;

  if (fAmount < minimumAmout) {
    fixFees = 5;
    percentage = 10;
  } else {
    fixFees = 2;
    percentage = 13;
  }

  let transactionFees = (percentage / 100) * fAmount;
  transactionFees += fixFees;
  return transactionFees;
};

export const getCryptoPaymentFees = ({ amount }: IBalanceFees) => {
  const fAmount = numeral(amount).value() || 0;
  const minimumAmount = 50;
  let transactionFees = 2.5;

  if (fAmount > minimumAmount) {
    transactionFees = (5 / 100) * fAmount;
  }

  return transactionFees;
};

// Agent Custom Fees
export const getAgentSpecialProductFee = (
  setting: ISetting,
  amount: number
) => {
  const { agentSetting } = setting;
  const { BANK, PAYPAL } = TransactionType;
  const { bankRewardFees, paypalFees, physicalCardFees } = agentSetting;
  const { transactionType } = setting;
  if (isEmpty(agentSetting)) {
    return 0;
  }
  let agentTransactionFees: any =
    (agentSetting?.bankRewardFees || 0 / 100) * amount;
  if (transactionType === BANK) {
    agentTransactionFees = bankRewardFees;
  } else if (transactionType === PAYPAL) {
    agentTransactionFees = paypalFees;
  } else {
    agentTransactionFees = physicalCardFees;
  }
  const totalFee = numeral(agentTransactionFees).value();
  return totalFee;
};

export const handleUserSpecialProductFees = (setting: ISetting) => {
  const {
    agentBankRewardFees,
    bankRewardFees,
    paypalFees,
    transactionType,
    isAgent,
  } = setting;
  const {
    agentPayPalFees,
    physicalCardFees,
    paymeFees,
    agentPhysicalCardFees,
  } = setting;
  const { BANK, PHYSICALCARD, PAYME } = TransactionType;
  let userFee: any = isAgent ? agentPayPalFees : paypalFees;
  if (transactionType === BANK) {
    userFee = isAgent ? agentBankRewardFees : bankRewardFees;
  }
  if (transactionType === PHYSICALCARD) {
    userFee = isAgent ? agentPhysicalCardFees : physicalCardFees;
  }
  if (transactionType === PAYME) {
    userFee = paymeFees;
  }
  return userFee;
};

export const getUserVirtualCardFee = (setting: ISetting, amount: number) => {
  const { isAgent, agentVirtualCardFees, virtualCardFees } = setting;
  const userFee = isAgent ? agentVirtualCardFees : virtualCardFees;
  const formattedAmount = numeral(amount).value() || 0;
  const minFixFee = 1;
  const maxFixFee = 3;
  let feePercentage = numeral(userFee).value() || 0;
  const isGreaterThan100 = formattedAmount > 100;
  if (isGreaterThan100) {
    feePercentage = 3;
  }
  let vCardFees = (feePercentage / 100) * formattedAmount;
  if (isGreaterThan100) {
    vCardFees += maxFixFee;
  } else {
    vCardFees += minFixFee;
  }
  return vCardFees;
};
export const getUserCardFee = (setting: ISetting, amount: number) => {
  const { isAgent, agentCardFees, cardFees } = setting;
  const userFee = isAgent ? agentCardFees : cardFees;
  const fixedFee = 0.5;
  const feePercentage = numeral(userFee).value() || 0;
  const formattedAmount = numeral(amount).value() || 0;
  const userCardFee = (feePercentage / 100) * formattedAmount;
  const finalFee = userCardFee + fixedFee;
  return finalFee;
};
export const getAgentTopUpFee = (setting: ISetting, amount: number) => {
  const { agentSetting } = setting;
  if (isEmpty(agentSetting)) {
    return 0;
  }
  const agentTopUpFee = (agentSetting.topUpFees / 100) * amount;
  const totalFee = numeral(agentTopUpFee).value();
  return totalFee;
};
export const getAgentTransferFee = (setting: ISetting, amount: number) => {
  const { agentSetting } = setting;
  if (isEmpty(agentSetting)) {
    return 0;
  }
  const agentTransferFee = (agentSetting.transFerFees / 100) * amount;
  const totalFee = numeral(agentTransferFee).value();
  return totalFee || 0;
};
export const getAgentVirtualCardFee = (setting: ISetting, amount: number) => {
  const { agentSetting } = setting;
  if (isEmpty(agentSetting)) {
    return 0;
  }
  const agentVirtualCardFee = (agentSetting.virtualCardFees / 100) * amount;
  const totalFee = numeral(agentVirtualCardFee).value();
  return totalFee;
};
export const getTotalTransferFee = (setting: ISetting, amount: number) => {
  const agentFee = getAgentTransferFee(setting, amount);

  return agentFee;
};
export const getTotalVirtualCardFee = (setting: ISetting, amount: number) => {
  const userFee = getUserVirtualCardFee(setting, amount);
  const agentFee = getAgentVirtualCardFee(setting, amount) || 0;
  if (!setting.isAgent) {
    return userFee;
  }
  const totalFee = userFee + agentFee;
  return totalFee;
};
export const getTotalTopUpFee = (setting: ISetting, amount: number) => {
  const agentFee = getAgentTopUpFee(setting, amount) || 0;

  return agentFee;
};

export const calculateCardCredit = (user: IUser) => {
  let creditAmount = 0;

  if (user?.cardHolderId && user?.tpCardHolderId) {
    // Add 13 credits to user that has had both the masterCard and Visa
    creditAmount = 12;
  }
  // User has Visa but not Mastercard
  else if (user?.cardHolderId && !user?.tpCardHolderId) {
    creditAmount = 9;
  }

  // User has Mastercard but not Visa
  else if (!user?.cardHolderId && user?.tpCardHolderId) {
    creditAmount = 9;
  } else {
    creditAmount = 0;
  }

  return creditAmount;
};

export const getSogebankDepositFees = (
  amount: number,
  globalSettings: IGlobalSettings
) => {
  const fees = globalSettings?.sogebankDepositFees;
  const percentageFees = globalSettings?.sogebankDepositPercentageFees;

  const depositFees = fees + (percentageFees / 100) * amount;

  return depositFees;
};

export const getVirtualCardDepositFees = ({
  amount,
  globalCard,
  delivery,
  latestOrders,
}: {
  amount: number;
  latestOrders: IOrderHistory[];
  globalCard: IGlobalCardSetting;
  delivery?: GiftCardDelivery;
}) => {
  const standardFees = globalCard?.standardDeliveryFee;
  const discountedStandardFees = globalCard?.discountedStandardDeliveryFee;
  const regularFees = globalCard?.regularDeliveryFee;
  const expressFees = globalCard?.expressDeliveryFee;
  const urgentFees = globalCard?.urgentDeliveryFee;
  const instantFees = globalCard?.instantDeliveryFee;
  const discountedInstant = globalCard?.discountedInstantDeliveryFee;

  // Default percentage fees
  let percentageFees = Number(globalCard?.depositFeePercentage);
  const fixedDepositFees = Number(globalCard?.fixedDepositFee || 0);

  const isDiscounted = isEligibleForDiscount(latestOrders, amount);

  // Set percentage fees based on delivery
  if (delivery === GiftCardDelivery.STANDARD) {
    percentageFees = isDiscounted
      ? discountedStandardFees
      : standardFees || percentageFees;
  } else if (delivery === GiftCardDelivery.REGULAR) {
    percentageFees = regularFees || percentageFees;
  } else if (delivery === GiftCardDelivery.EXPRESS) {
    percentageFees = expressFees || percentageFees;
  } else if (delivery === GiftCardDelivery.URGENT) {
    percentageFees = urgentFees || percentageFees;
  } else if (delivery === GiftCardDelivery.INSTANT) {
    percentageFees = isDiscounted
      ? discountedInstant
      : instantFees || percentageFees;
  }

  const depositFees = (percentageFees / 100) * amount;

  return depositFees + fixedDepositFees;
};

export const getWithdrawFees = ({
  amount,
  setting,
  delivery,
}: {
  amount: number;
  setting: IGlobalWithdrawSettings;
  delivery?: GiftCardDelivery;
}) => {
  const standardFees = setting?.standardDeliveryFee;
  const regularFees = setting?.regularDeliveryFee;
  const expressFees = setting?.expressDeliveryFee;
  const urgentFees = setting?.urgentDeliveryFee;
  const instantFees = setting?.instantDeliveryFee;

  // Default percentage fees
  let percentageFees = Number(setting?.feePercentage);
  const fixedFees = Number(setting?.fixedFee || 0);

  // Set percentage fees based on delivery
  if (delivery === GiftCardDelivery.STANDARD) {
    percentageFees = standardFees || percentageFees;
  } else if (delivery === GiftCardDelivery.REGULAR) {
    percentageFees = regularFees || percentageFees;
  } else if (delivery === GiftCardDelivery.EXPRESS) {
    percentageFees = expressFees || percentageFees;
  } else if (delivery === GiftCardDelivery.URGENT) {
    percentageFees = urgentFees || percentageFees;
  } else if (delivery === GiftCardDelivery.INSTANT) {
    percentageFees = instantFees || percentageFees;
  }

  const depositFees = (percentageFees / 100) * amount;

  return depositFees + fixedFees || 0;
};

export const getDepositFees = ({
  amount,
  setting,
}: {
  amount: number;
  setting: IGlobalSettingsUpdate;
}) => {
  // Default percentage fees
  const percentageFees = Number(setting?.depositPercentageFees);
  const fixedFees = Number(setting?.depositFixedFees || 0);

  const depositFees = (percentageFees / 100) * amount;

  return depositFees + fixedFees || 0;
};

export const getBUhDepositFees = (amount: number, globalSetting) => {
  const percentage = globalSetting?.buhDepositPercentageFees;
  const depositFees = globalSetting?.buhDepositFees;
  let fees = (percentage / 100) * amount;

  if (depositFees) {
    fees += depositFees;
  }

  return fees;
};

export const getBUhHTGDepositFees = (amount: number, globalSetting) => {
  const percentage = globalSetting?.buhDepositPercentageFees;
  const depositFees = globalSetting?.buhDepositFees;
  const exchangeRates = globalSetting?.buhDepositRates;
  const amountInUSD = amount / exchangeRates;

  let fees = (percentage / 100) * amountInUSD;

  if (depositFees) {
    fees += depositFees;
  }

  return fees;
};

export const getUnibankDepositFees = (amount: number) => {
  const fees = (5 / 100) * amount;

  return fees;
};

export const getExchangeAmount = (
  transactionType: TransactionType,
  globalSetting: IGlobalSettings
) => {
  let exchangeAmount = globalSetting?.monCashFees;

  switch (transactionType) {
    case TransactionType.NATCASH_DEPOSIT:
      exchangeAmount = globalSetting?.natCashDepositRates;
      break;

    case TransactionType.NATCASH_WITHDRAW:
      exchangeAmount = globalSetting?.natCashWithdrawFees;
      break;

    case TransactionType.MONCASH_DEPOSIT:
      exchangeAmount =
        globalSetting?.moncashManualDepositRates || globalSetting?.monCashFees;
      break;

    case TransactionType.UNIBANK_DEPOSIT:
      exchangeAmount = globalSetting?.unibankDepositRates;
      break;

    case TransactionType.SOGEBANK_DEPOSIT:
      exchangeAmount = globalSetting?.sogebankDepositRates;
      break;

    case TransactionType.BUH_DEPOSIT:
      exchangeAmount = globalSetting?.buhDepositRates;
      break;

    case TransactionType.BANK_RESERVAS_DEPOSIT:
    case TransactionType.BANK_BHD_LEON_DEPOSIT:
      exchangeAmount = globalSetting?.dopExchangeRateAmount;
      break;

    default:
      exchangeAmount = globalSetting?.monCashFees;
      break;
  }

  return exchangeAmount;
};

export const getFeePercantage = (
  transactionType: TransactionType,
  globalSetting: IGlobalSettings
) => {
  let exchangeAmount = globalSetting?.moncashAutomaticDepositPercentageFee;

  switch (transactionType) {
    case TransactionType.NATCASH_DEPOSIT:
      exchangeAmount = globalSetting?.natCashDepositPercentageFees;
      break;

    case TransactionType.NATCASH_WITHDRAW:
      exchangeAmount = globalSetting?.natCashDepositPercentageFees;
      break;

    case TransactionType.MONCASH_DEPOSIT:
      exchangeAmount = globalSetting?.moncashAutomaticDepositPercentageFee;
      break;

    case TransactionType.BUH_DEPOSIT:
      exchangeAmount = globalSetting?.buhDepositPercentageFees;
      break;

    default:
      exchangeAmount = globalSetting?.moncashAutomaticDepositPercentageFee;
      break;
  }

  return exchangeAmount;
};
