export interface IBank {
  id?: string;
  bankName?: string;
  accountId?: string;
  bankId?: string;
  itemId?: string;
  accessToken?: string;
  accountName?: string;
  accountType?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IPlaidBankResponse {
  public_token: string;
  account_id: string;
  transfer_status: string;
  wallet: string;
  link_session_id: string;
  institution: {
    name: string;
    institution_id: string;
  };
  account: {
    id: string;
    name: string;
    mask: string;
    type: string;
    subtype: string;
    verification_status: string;
    class_type: string;
  };
  accounts: {
    id: string;
    name: string;
    mask: string;
    type: string;
    subtype: string;
    verification_status: string;
    class_type: string;
  }[];
}

export enum IBankName {
  // USA Bank
  PAYONEER = "payoneer",
  MERCURY = "mercury",
  BANK_OF_AMERICA = "bank_of_america",
  WISE = "wise",

  // Haiti Bank
  SOGEBANK = "sogebank",
  BUH = "buh",
  UNIBANK = "unibank",
  CAPITAL_BANK = "capital_bank",
  BNC = "bnc",

  // Dominican Republic Bank
  BANK_RESERVAS = "bank_of_reservas",
  BANK_BHD_LEON = "bank_bhd_leon",

  // Neo Bank
  PAYPAL = "paypal",
  CASHAPP = "cashapp",
  MONCASH = "moncash",
  NATCASH = "natcash",
  WESTERN_UNION = "western_union",
  CARIBE_EXPRESS = "caribe_express",
  ZELLE = "zelle",
  VENMO = "venmo",

  // Other
  PGECOM = "pgecom",
  UNITRANSFER = "unitransfer",
  MONEYGRAM = "moneygram",
  RIA = "ria",
  WORLD_REMIT = "world_remit",
  XOOM = "xoom",
  TRANSFERWISE = "transferwise",
  REMITLY = "remitly",
  AZIMO = "azimo",
}
