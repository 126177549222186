import { IOrderHistory, TransactionType, transactionTypeDomains } from "@Types";
import { isTransactionDeposit } from "./formatUtils";

// Is first order matches any of those conditions?
export const isEligibleForDiscount = (
  orders: IOrderHistory[],
  amount: number
) => {
  const supportedTransactionTypes = [
    TransactionType.ZELLE_DEPOSIT,
    TransactionType.CASHAPP_DEPOSIT,
    TransactionType.BINANCE_DEPOSIT,
    TransactionType.CRYPTO_DEPOSIT,
    TransactionType.WAVE_PAYMENT_DEPOSIT,
  ];

  if (orders.length === 0) return false;

  const deposits = orders?.filter((item) =>
    isTransactionDeposit(item.transactionType)
  );

  const [order] = deposits;

  const transactionType = order?.transactionType;

  if (amount > order?.amount) {
    // Check for the last 3 orders

    const lastOrders = deposits.slice(0, 3);

    // Find any order that is not supported
    const filterOrders = lastOrders.filter(
      (item) => !supportedTransactionTypes.includes(item.transactionType)
    );

    // We only want to apply the discount if the last 3 orders are not supported
    const isEligible = filterOrders?.length === 0;

    if (!isEligible) return false;

    return true;
  }

  // Is this a valid transaction type?
  if (!supportedTransactionTypes.includes(transactionType)) return false;

  return true;
};

export const getDomainFromTransactionType = (
  transactionType: TransactionType
): string => {
  // Check if the transaction type has a mapped domain
  const domain = transactionTypeDomains[transactionType];

  if (domain) {
    return domain;
  }

  // Fallback to default behavior if no domain is found
  return "";
};
